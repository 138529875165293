import consoleLogger from '../../../../modules/console-logger'
import includeHiddenOptionsButton from '../../modules/include-hidden-options-button'
import slcSetTargets from '../../modules/slc-set-targets'
import targets from '../../modules/targets'

const GOOGLETAG_WAIT_TIME = 50

function waitGoogletag(window, resolve, reject) {
  if (window.googletag) {
    try {
      if (window.googletag.apiReady && window.googletag.pubadsReady) {
        resolve()
      } else {
        setTimeout(() => waitGoogletag(window, resolve, reject), GOOGLETAG_WAIT_TIME)
      }
    } catch (e) {
      reject(e)
    }
  } else {
    setTimeout(() => waitGoogletag(window, resolve, reject), GOOGLETAG_WAIT_TIME)
  }
}

function getGoogletag(window) {
  return new Promise((resolve, reject) => waitGoogletag(window, resolve, reject))
}

// Missing:
// - injecting HTML/CSS
const initPubstack = ({ configs, viewportsConfig, adTargets } = {}) => {
  const [consoleLog] = consoleLogger('AdsLog')

  const generalConfigs = configs.general

  if (generalConfigs.hiddenOptions) {
    includeHiddenOptionsButton(generalConfigs.hiddenOptions)
  }

  getGoogletag(window).then(() => {
    slcSetTargets({ googletag })
    targets({
      configs: generalConfigs.targeting,
      googletag,
      adTargets,
      testValue: generalConfigs.testValue,
      targetsKeyPrefix: generalConfigs.targetsKeyPrefix,
    })
  })
}

export default initPubstack
