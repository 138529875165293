import consoleLogger from '../../../../modules/console-logger'
import handlerWindowDlab from '../handler-window-dlab'
import { clearSLCTargets } from '../slc-set-targets'
import { checkInjectSelector } from '../init-ads-inject-ad'

const destroyAdSettings = () => {
  const [consoleLog] = consoleLogger('Ads')
  const { dlabOption: dlabUnitsOption, clearDlabOption: clearDlabUnitOption } = handlerWindowDlab('ads', {
    sub: 'units',
  })
  const { dlabOption } = handlerWindowDlab('ads')
  try {
    window.googletag = window.googletag || { cmd: [] }
    googletag.cmd.push(function () {
      window.googletag.pubads().clearTargeting()
      window.googletag.pubads().clearCategoryExclusions()
      window.googletag.destroySlots(window.googletag.pubads().getSlots())

      if (dlabUnitsOption) {
        clearDlabUnitOption()
      }
      if (dlabOption) {
        clearSLCTargets()
        dlabOption.config.slots.forEach((slot) => {
          if (checkInjectSelector({ slot })) {
            document.getElementById(slot.id)?.parentElement.remove()
          }
        })
      }
      consoleLog('dlab ads deleted', window.dlab.ads)
      consoleLog('google ads DESTROYED', window.googletag.pubads().getSlots())
    })
  } catch (e) {
    consoleLog('destroyAdsSettings Error', e.message)
  }
}

const reloadPubstack = () => {
  const [consoleLog] = consoleLogger('Ads')
  try {
    consoleLog('reloadPubstack on Page - Trying!')
    if (window) {
      window.dispatchEvent(new CustomEvent('admSPAReload'))
      consoleLog('reloadPubstack on Page - GO!')
    }
  } catch (e) {
    consoleLog('reloadPubstack on Page Change - Error', e.message)
  }
}

export default destroyAdSettings
export { reloadPubstack }
